import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { FaBuilding, FaTools, FaUserTie, FaChartLine, FaArrowRight, FaClock, FaCheckCircle, FaHistory, FaHospital, FaShoppingBag, FaHotel, FaIndustry, FaSchool, FaHome, FaLandmark, FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { useState } from 'react'
import { services } from '../data/services'

const Home = () => {
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0)
  const maxServicesShown = 8
  const servicesPerPage = 4
  const showViewAll = currentServiceIndex + servicesPerPage >= maxServicesShown

  const handlePrevServices = () => {
    setCurrentServiceIndex(Math.max(0, currentServiceIndex - servicesPerPage))
  }

  const handleNextServices = () => {
    setCurrentServiceIndex(Math.min(maxServicesShown - servicesPerPage, currentServiceIndex + servicesPerPage))
  }

  const handleNavigation = () => {
    window.scrollTo(0, 0)
  }

  const visibleServices = services.slice(currentServiceIndex, currentServiceIndex + servicesPerPage)

  const features = [
    {
      icon: <FaBuilding className="text-3xl text-primary" />,
      title: 'Comprehensive Solutions',
      description: 'Complete facility management and maintenance services under one roof'
    },
    {
      icon: <FaTools className="text-3xl text-primary" />,
      title: 'Expert Team',
      description: 'Highly skilled professionals with extensive industry experience'
    },
    {
      icon: <FaUserTie className="text-3xl text-primary" />,
      title: 'Client-Focused',
      description: 'Tailored solutions to meet your specific facility requirements'
    },
    {
      icon: <FaChartLine className="text-3xl text-primary" />,
      title: 'Quality Assured',
      description: 'Commitment to excellence in every service we deliver'
    }
  ]

  const clients = [
    {
      name: 'Dubai Mall',
      logo: 'https://placehold.co/200x80/e2e8f0/475569?text=Dubai+Mall',
    },
    {
      name: 'Burj Khalifa',
      logo: 'https://placehold.co/200x80/e2e8f0/475569?text=Burj+Khalifa',
    },
    {
      name: 'Emirates Towers',
      logo: 'https://placehold.co/200x80/e2e8f0/475569?text=Emirates+Towers',
    },
    {
      name: 'Mall of Emirates',
      logo: 'https://placehold.co/200x80/e2e8f0/475569?text=Mall+of+Emirates',
    },
    {
      name: 'Dubai Airport',
      logo: 'https://placehold.co/200x80/e2e8f0/475569?text=Dubai+Airport',
    },
    {
      name: 'Abu Dhabi Mall',
      logo: 'https://placehold.co/200x80/e2e8f0/475569?text=Abu+Dhabi+Mall',
    },
    {
      name: 'Yas Mall',
      logo: 'https://placehold.co/200x80/e2e8f0/475569?text=Yas+Mall',
    },
    {
      name: 'Dubai Marina Mall',
      logo: 'https://placehold.co/200x80/e2e8f0/475569?text=Dubai+Marina+Mall',
    }
  ]

  const stats = [
    {
      number: '500+',
      label: 'Projects Completed',
      icon: <FaBuilding className="w-8 h-8" />
    },
    {
      number: '15+',
      label: 'Years Experience',
      icon: <FaHistory className="w-8 h-8" />
    },
    {
      number: '98%',
      label: 'Client Satisfaction',
      icon: <FaCheckCircle className="w-8 h-8" />
    },
    {
      number: '24/7',
      label: 'Support Available',
      icon: <FaClock className="w-8 h-8" />
    }
  ]

  const sectors = [
    {
      title: 'Commercial',
      description: 'Office complexes and business centers',
      icon: <FaBuilding className="w-8 h-8" />
    },
    {
      title: 'Healthcare',
      description: 'Hospitals and medical facilities',
      icon: <FaHospital className="w-8 h-8" />
    },
    {
      title: 'Retail',
      description: 'Shopping malls and retail outlets',
      icon: <FaShoppingBag className="w-8 h-8" />
    },
    {
      title: 'Hospitality',
      description: 'Hotels and entertainment venues',
      icon: <FaHotel className="w-8 h-8" />
    },
    {
      title: 'Industrial',
      description: 'Manufacturing and industrial facilities',
      icon: <FaIndustry className="w-8 h-8" />
    },
    {
      title: 'Education',
      description: 'Schools and universities',
      icon: <FaSchool className="w-8 h-8" />
    },
    {
      title: 'Residential',
      description: 'Luxury apartments and compounds',
      icon: <FaHome className="w-8 h-8" />
    },
    {
      title: 'Government',
      description: 'Public facilities and institutions',
      icon: <FaLandmark className="w-8 h-8" />
    }
  ]

  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[80vh] flex items-center overflow-hidden">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-r from-secondary/90 to-primary/90" />
          <img
            src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&w=1600&q=80"
            alt="Building Maintenance"
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className="container relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl text-white"
          >
            <h1 className="text-5xl md:text-6xl font-display font-bold mb-6">
              Excellence First.<br />
              Lasting Value.
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              Comprehensive building maintenance and facility management solutions for modern businesses
            </p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="flex gap-4"
            >
              <Link to="/contact" className="btn btn-primary">
                Get Started
              </Link>
              <Link to="/services" className="btn btn-outline-white">
                Our Services
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Services Preview */}
      <section className="py-20 bg-white">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-display font-bold mb-4">Our Services</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Comprehensive facility management solutions tailored to your needs
            </p>
          </motion.div>

          <div className="relative">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {visibleServices.map((service, index) => (
                <motion.div
                  key={service.id}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="group relative bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 flex flex-col h-full"
                >
                  <div className="relative h-32 overflow-hidden rounded-t-lg">
                    <img
                      src={service.image}
                      alt={service.title}
                      className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                    />
                    <div className="absolute inset-0 bg-black/10 group-hover:bg-black/0 transition-colors duration-300" />
                  </div>
                  <div className="p-4 flex flex-col flex-grow">
                    <h3 className="text-lg font-bold mb-2 line-clamp-1">{service.title}</h3>
                    <p className="text-sm text-gray-600 mb-4 line-clamp-2 flex-grow">{service.shortDescription}</p>
                    <Link
                      to={`/services/${service.id}`}
                      onClick={handleNavigation}
                      className="inline-flex items-center text-sm text-primary hover:text-primary-600 transition-colors mt-auto"
                    >
                      Learn More <FaArrowRight className="ml-1 w-3 h-3" />
                    </Link>
                  </div>
                </motion.div>
              ))}
            </div>

            {/* Navigation Buttons */}
            <div className="flex justify-center items-center mt-6 space-x-2">
              <button
                onClick={handlePrevServices}
                disabled={currentServiceIndex === 0}
                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  currentServiceIndex === 0
                    ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                    : 'bg-white text-primary hover:bg-primary hover:text-white shadow-sm'
                } transition-all duration-300`}
                aria-label="Previous services"
              >
                <FaChevronLeft className="w-3 h-3" />
              </button>

              {showViewAll ? (
                <Link
                  to="/services"
                  onClick={handleNavigation}
                  className="px-4 py-1.5 bg-primary text-white text-sm rounded-full hover:bg-primary-600 transition-colors duration-300 shadow-sm"
                >
                  View All Services
                </Link>
              ) : (
                <button
                  onClick={handleNextServices}
                  disabled={currentServiceIndex + servicesPerPage >= maxServicesShown}
                  className={`w-8 h-8 rounded-full flex items-center justify-center ${
                    currentServiceIndex + servicesPerPage >= maxServicesShown
                      ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                      : 'bg-white text-primary hover:bg-primary hover:text-white shadow-sm'
                  } transition-all duration-300`}
                  aria-label="Next services"
                >
                  <FaChevronRight className="w-3 h-3" />
                </button>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="py-20 bg-gray-50">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-display font-bold mb-4">Why Choose Us</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Experience excellence in facility management with our comprehensive solutions
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="card p-8 text-center hover:translate-y-[-8px] transition-all duration-300"
              >
                <div className="inline-block p-4 rounded-full bg-primary-50 mb-6">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Service Excellence in Numbers */}
      <section className="py-20 bg-gradient-to-br from-primary/90 to-secondary/90 text-white">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-display font-bold mb-4">Service Excellence in Numbers</h2>
            <p className="text-xl opacity-90 max-w-2xl mx-auto">
              Our track record speaks for itself through these key performance indicators
            </p>
          </motion.div>

          <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 md:gap-12">
            {stats.map((stat, index) => (
              <motion.div
                key={stat.label}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="text-center p-8 rounded-lg bg-white/10 backdrop-blur-sm hover:bg-white/20 transition-all duration-300"
              >
                <div className="mb-4 flex justify-center">{stat.icon}</div>
                <div className="text-4xl md:text-5xl font-bold mb-2 font-display">
                  {stat.number}
                </div>
                <div className="text-lg opacity-90">{stat.label}</div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Sectors We Serve */}
      <section className="py-20 bg-white">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-display font-bold mb-4">Sectors We Serve</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Delivering excellence across diverse industries with tailored facility management solutions
            </p>
          </motion.div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {sectors.map((sector, index) => (
              <motion.div
                key={sector.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="group p-6 rounded-lg bg-white shadow-sm hover:shadow-md transition-all duration-300"
              >
                <div className="text-primary mb-4 transform group-hover:scale-110 transition-transform duration-300">
                  {sector.icon}
                </div>
                <h3 className="text-lg font-bold mb-2">{sector.title}</h3>
                <p className="text-sm text-gray-600">{sector.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Key Clients Section */}
      <section className="py-20 bg-gray-50">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-display font-bold mb-4">Our Key Clients</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Trusted by leading organizations across the UAE
            </p>
          </motion.div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {clients.map((client, index) => (
              <motion.div
                key={client.name}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="group relative bg-white rounded-lg shadow-md p-6 flex items-center justify-center hover:shadow-lg transition-all duration-300"
              >
                <div className="relative w-full aspect-[3/1]">
                  <img
                    src={client.logo}
                    alt={client.name}
                    className="w-full h-full object-contain filter grayscale group-hover:grayscale-0 transition-all duration-300"
                  />
                  <div className="absolute inset-0 bg-primary/0 group-hover:bg-primary/5 transition-colors duration-300 rounded-lg" />
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-br from-primary/90 to-secondary/90 text-white">
        <div className="container text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="max-w-3xl mx-auto"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              Ready to Optimize Your Facility Management?
            </h2>
            <p className="text-xl mb-8">
              Let&apos;s discuss how our services can enhance your building&apos;s performance and efficiency
            </p>
            <div className="flex flex-wrap gap-4 justify-center">
              <Link to="/contact" className="btn bg-white text-primary hover:bg-white/90">
                Contact Us
              </Link>
              <Link to="/services" className="btn border-2 border-white text-white hover:bg-white hover:text-primary">
                Explore Services
              </Link>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  )
}

export default Home 