import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaLinkedin, FaTwitter, FaTimes } from 'react-icons/fa'
import { motion, AnimatePresence } from 'framer-motion'
import Footer from '../components/Footer'
import Clarity from '@microsoft/clarity'

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()

  // Initialize Clarity
  useEffect(() => {
    Clarity.init("paxyczeyx1")
  }, [])

  // Track page views and identify sessions
  useEffect(() => {
    // Scroll to top when route changes
    window.scrollTo(0, 0)
    
    // Track page view in Clarity
    Clarity.identify(location.pathname)
    Clarity.setTag("page", location.pathname)
    
    // Track page view as custom event
    Clarity.event(`page_view_${location.pathname}`)
  }, [location.pathname])

  const handleNavigation = (path, label) => {
    // Track navigation click events
    Clarity.event(`nav_click_${label.toLowerCase()}`)
    setIsMenuOpen(false)
  }

  const navLinks = [
    { path: '/', label: 'Home' },
    { 
      path: '/services', 
      label: 'Services',
      submenu: [
        { path: '/services/fm-audit', label: 'FM Audit' },
        { path: '/services/hard-services', label: 'Hard Services' },
        { path: '/services/soft-services', label: 'Soft Services' },
        { path: '/services/mep-installation', label: 'MEP Installation' },
      ]
    },
    { path: '/projects', label: 'Projects' },
    { path: '/about', label: 'About' },
    { path: '/careers', label: 'Careers' },
    { path: '/contact', label: 'Contact' },
    { path: '/certifications', label: 'Certifications' }
  ]

  return (
    <div className="min-h-screen flex flex-col">
      {/* Top Bar */}
      <div className="bg-secondary text-white py-2">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-6 text-sm">
              <a 
                href="tel:+97126712526" 
                className="flex items-center space-x-2"
                onClick={() => Clarity.event('phone_click')}
              >
                <FaPhone className="text-primary" />
                <span>Tel: +971 2 6712526</span>
              </a>
              <a 
                href="mailto:contact@theelgm.com" 
                className="flex items-center space-x-2"
                onClick={() => Clarity.event('email_click')}
              >
                <FaEnvelope className="text-primary" />
                <span>Email: contact@theelgm.com</span>
              </a>
              <div className="flex items-center space-x-2">
                <FaMapMarkerAlt className="text-primary" />
                <span>Dubai, UAE</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation */}
      <nav className="bg-white shadow-sm sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center h-20">
            {/* Logo */}
            <Link 
              to="/" 
              className="flex items-center"
              onClick={() => Clarity.event('logo_click')}
            >
              <img src="/elgm.png" alt="ELGM" className="h-16" />
            </Link>

            {/* Desktop Navigation */}
            <div className="hidden lg:flex items-center space-x-8">
              {navLinks.map((link) => (
                <Link
                  key={link.path}
                  to={link.path}
                  className="text-gray-600 hover:text-primary transition-colors"
                  onClick={() => handleNavigation(link.path, link.label)}
                >
                  {link.label}
                </Link>
              ))}
            </div>

            {/* Mobile Menu Button */}
            <button
              className="lg:hidden p-2 rounded-md text-gray-600 hover:text-primary hover:bg-gray-100"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen)
                Clarity.event(isMenuOpen ? 'mobile_menu_close' : 'mobile_menu_open')
              }}
            >
              <span className="sr-only">Open menu</span>
              {isMenuOpen ? (
                <FaTimes className="h-6 w-6" />
              ) : (
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="lg:hidden fixed inset-0 z-40">
          <div className="fixed inset-0 bg-black bg-opacity-50" onClick={() => setIsMenuOpen(false)} />
          <div className="fixed inset-y-0 right-0 w-64 bg-white shadow-xl z-50">
            <div className="p-6">
              <div className="flex justify-end mb-6">
                <button
                  className="p-2 rounded-md text-gray-600 hover:text-primary hover:bg-gray-100"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <FaTimes className="h-6 w-6" />
                </button>
              </div>
              <nav className="space-y-4">
                {navLinks.map((link) => (
                  <Link
                    key={link.path}
                    to={link.path}
                    className="block text-gray-600 hover:text-primary transition-colors py-2"
                    onClick={() => handleNavigation(link.path, link.label)}
                  >
                    {link.label}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <main className="flex-grow">
        {children}
      </main>

      {/* Footer */}
      <Footer />
    </div>
  )
}

export default Layout 