import { motion } from 'framer-motion'
import { FaBriefcase, FaGraduationCap, FaClock, FaMapMarkerAlt } from 'react-icons/fa'

const Careers = () => {
  const jobOpenings = [
    {
      title: 'Facility Manager',
      type: 'Full Time',
      location: 'Abu Dhabi',
      experience: '5+ years',
      description: 'We are looking for an experienced Facility Manager to oversee our operations.',
      requirements: [
        "Bachelor's degree in Engineering or related field",
        'Proven experience in facility management',
        'Strong leadership and communication skills',
        'Knowledge of UAE building regulations'
      ]
    },
    {
      title: 'Maintenance Technician',
      type: 'Full Time',
      location: 'Abu Dhabi',
      experience: '3+ years',
      description: 'Seeking skilled maintenance technicians for our growing team.',
      requirements: [
        'Technical diploma or certification',
        'Experience in building maintenance',
        'Knowledge of HVAC systems',
        'Strong problem-solving skills'
      ]
    },
    {
      title: 'MEP Engineer',
      type: 'Full Time',
      location: 'Abu Dhabi',
      experience: '4+ years',
      description: 'Looking for an experienced MEP Engineer to join our technical team.',
      requirements: [
        "Bachelor's degree in Mechanical/Electrical Engineering",
        'MEP systems experience',
        'Project management skills',
        'AutoCAD proficiency'
      ]
    }
  ]

  const benefits = [
    {
      icon: <FaBriefcase className="text-3xl text-primary" />,
      title: 'Career Growth',
      description: 'Opportunities for professional development and advancement'
    },
    {
      icon: <FaGraduationCap className="text-3xl text-primary" />,
      title: 'Training',
      description: 'Continuous learning and skill development programs'
    },
    {
      icon: <FaClock className="text-3xl text-primary" />,
      title: 'Work-Life Balance',
      description: 'Flexible working hours and competitive leave policy'
    },
    {
      icon: <FaMapMarkerAlt className="text-3xl text-primary" />,
      title: 'Location',
      description: 'Prime location in Abu Dhabi with easy accessibility'
    }
  ]

  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[40vh] flex items-center overflow-hidden">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-r from-secondary/90 to-primary/90" />
          <img
            src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?auto=format&fit=crop&w=1600&q=80"
            alt="Careers"
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className="container relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl text-white"
          >
            <h1 className="text-5xl md:text-6xl font-display font-bold mb-6">
              Join Our Team
            </h1>
            <p className="text-xl md:text-2xl">
              Build your career with the leading facility management company in UAE
            </p>
          </motion.div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 bg-light">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl lg:text-4xl font-bold text-dark mb-4">
              Why Work With Us?
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Join a team that values innovation, growth, and excellence
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white p-8 rounded-lg shadow-lg text-center"
              >
                <div className="mb-4">{benefit.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Current Openings */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl lg:text-4xl font-bold text-dark mb-4">
              Current Openings
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Explore our current job opportunities and find your perfect role
            </p>
          </motion.div>

          <div className="space-y-8">
            {jobOpenings.map((job, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white p-8 rounded-lg shadow-lg"
              >
                <div className="flex flex-wrap justify-between items-start mb-6">
                  <div>
                    <h3 className="text-2xl font-bold text-dark mb-2">{job.title}</h3>
                    <div className="flex flex-wrap gap-4 text-gray-600">
                      <span className="flex items-center gap-1">
                        <FaBriefcase /> {job.type}
                      </span>
                      <span className="flex items-center gap-1">
                        <FaMapMarkerAlt /> {job.location}
                      </span>
                      <span className="flex items-center gap-1">
                        <FaClock /> {job.experience}
                      </span>
                    </div>
                  </div>
                  <a
                    href="#apply-now"
                    className="bg-primary text-white px-6 py-2 rounded-lg font-semibold hover:bg-secondary transition-colors mt-4 sm:mt-0"
                  >
                    Apply Now
                  </a>
                </div>
                <p className="text-gray-600 mb-4">{job.description}</p>
                <h4 className="font-semibold text-dark mb-2">Requirements:</h4>
                <ul className="list-disc list-inside text-gray-600 space-y-1">
                  {job.requirements.map((req, reqIndex) => (
                    <li key={reqIndex}>{req}</li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Application Form */}
      <section id="apply-now" className="py-20 bg-light">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg"
          >
            <h2 className="text-2xl font-bold mb-6">Apply Now</h2>
            <iframe
              data-tally-src="[YOUR_FORM_URL]?alignLeft=1&transparentBackground=1&dynamicHeight=1&hideTitle=1&buttonBg=2563eb&buttonText=FFFFFF"
              loading="lazy"
              width="100%"
              height="500"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Job application form"
            ></iframe>
          </motion.div>
        </div>
      </section>
    </div>
  )
}

export default Careers 