export const services = [
  {
    id: 'mechanical-electrical',
    title: 'Mechanical and Electrical Maintenance',
    shortDescription: 'Comprehensive maintenance of mechanical and electrical systems for optimal building performance.',
    icon: 'FaBolt',
    image: 'https://images.unsplash.com/photo-1581094794329-c8112a89af12?auto=format&fit=crop&w=800&q=80',
    path: '/services/mechanical-electrical',
    features: [
      'Electrical System Maintenance',
      'Mechanical Equipment Service',
      'Preventive Maintenance',
      'Emergency Repairs',
      'System Upgrades',
      'Performance Monitoring'
    ],
    detailedDescription: `Our mechanical and electrical maintenance services ensure the reliable operation of your building's critical systems through regular inspections, maintenance, and prompt repairs.`,
    benefits: [
      {
        title: 'Expert Service',
        description: 'Highly qualified technicians and engineers'
      },
      {
        title: 'Preventive Care',
        description: 'Regular maintenance to prevent system failures'
      },
      {
        title: 'Emergency Response',
        description: '24/7 emergency support'
      }
    ]
  },
  {
    id: 'plumbing',
    title: 'Plumbing Maintenance',
    shortDescription: 'Professional plumbing maintenance and repair services for all building types.',
    icon: 'FaWater',
    image: 'https://images.unsplash.com/photo-1585704032915-c3400ca199e7?auto=format&fit=crop&w=800&q=80',
    path: '/services/plumbing',
    features: [
      'Pipe Maintenance',
      'Leak Detection',
      'Drain Cleaning',
      'Fixture Repairs',
      'Water System Maintenance',
      'Emergency Services'
    ],
    detailedDescription: `Our plumbing maintenance services cover all aspects of building plumbing systems, ensuring efficient water distribution and waste management.`,
    benefits: [
      {
        title: 'Professional Service',
        description: 'Experienced plumbing technicians'
      },
      {
        title: 'Quick Response',
        description: 'Rapid response to emergencies'
      },
      {
        title: 'Quality Work',
        description: 'Guaranteed workmanship'
      }
    ]
  },
  {
    id: 'air-conditioning',
    title: 'Air Conditioning',
    shortDescription: 'Complete air conditioning maintenance and repair services for optimal climate control.',
    icon: 'FaSnowflake',
    image: 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?auto=format&fit=crop&w=800&q=80',
    path: '/services/air-conditioning',
    features: [
      'AC Unit Maintenance',
      'System Repairs',
      'Performance Optimization',
      'Filter Replacement',
      'Duct Cleaning',
      'Temperature Control'
    ],
    detailedDescription: `Our air conditioning services maintain optimal indoor climate through regular maintenance, repairs, and system optimization.`,
    benefits: [
      {
        title: 'Comfort Control',
        description: 'Optimal temperature and humidity levels'
      },
      {
        title: 'Energy Efficiency',
        description: 'Reduced energy consumption'
      },
      {
        title: 'System Longevity',
        description: 'Extended equipment life'
      }
    ]
  },
  {
    id: 'civil-refurbishment',
    title: 'Civil Refurbishment',
    shortDescription: 'Comprehensive civil works and building refurbishment services.',
    icon: 'FaHardHat',
    image: 'https://images.unsplash.com/photo-1503387762-592deb58ef4e?auto=format&fit=crop&w=800&q=80',
    path: '/services/civil-refurbishment',
    features: [
      'Structural Repairs',
      'Building Renovation',
      'Waterproofing',
      'Painting Works',
      'Flooring',
      'General Repairs'
    ],
    detailedDescription: `Our civil refurbishment services cover all aspects of building renovation and repair, ensuring structural integrity and aesthetic appeal.`,
    benefits: [
      {
        title: 'Quality Work',
        description: 'High-standard workmanship'
      },
      {
        title: 'Project Management',
        description: 'Efficient project execution'
      },
      {
        title: 'Value Addition',
        description: 'Enhanced building value'
      }
    ]
  },
  {
    id: 'interior-fitout',
    title: 'Interior Fit Out Works',
    shortDescription: 'Professional interior fit-out services for commercial and residential spaces.',
    icon: 'FaPaintRoller',
    image: 'https://images.unsplash.com/photo-1618221195710-dd6b41faaea6?auto=format&fit=crop&w=800&q=80',
    path: '/services/interior-fitout',
    features: [
      'Space Planning',
      'Interior Design',
      'Partition Works',
      'Ceiling Works',
      'Flooring Installation',
      'Finishing Works'
    ],
    detailedDescription: `Our interior fit-out services transform spaces with high-quality finishes and installations, creating functional and aesthetically pleasing environments.`,
    benefits: [
      {
        title: 'Custom Solutions',
        description: 'Tailored to your requirements'
      },
      {
        title: 'Quality Materials',
        description: 'Premium materials and finishes'
      },
      {
        title: 'Professional Execution',
        description: 'Expert installation teams'
      }
    ]
  },
  {
    id: 'swimming-pool',
    title: 'Swimming Pool Maintenance',
    shortDescription: 'Complete swimming pool maintenance and cleaning services.',
    icon: 'FaSwimmingPool',
    image: 'https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?auto=format&fit=crop&w=800&q=80',
    path: '/services/swimming-pool',
    features: [
      'Water Treatment',
      'Chemical Balance',
      'Filter Maintenance',
      'Equipment Service',
      'Pool Cleaning',
      'Safety Checks'
    ],
    detailedDescription: `Our swimming pool maintenance services ensure clean, safe, and properly maintained pools through regular service and monitoring.`,
    benefits: [
      {
        title: 'Water Quality',
        description: 'Safe and clean pool water'
      },
      {
        title: 'Regular Service',
        description: 'Scheduled maintenance'
      },
      {
        title: 'Expert Care',
        description: 'Professional pool technicians'
      }
    ]
  },
  {
    id: 'window-cleaning',
    title: 'Window Glass Cleaning',
    shortDescription: 'Professional window cleaning services for buildings of all heights.',
    icon: 'FaSprayCan',
    image: 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?auto=format&fit=crop&w=800&q=80',
    path: '/services/window-cleaning',
    features: [
      'High-rise Cleaning',
      'Safety Equipment',
      'Spot-free Finish',
      'Regular Service',
      'Special Access Equipment',
      'Weather Monitoring'
    ],
    detailedDescription: `Our window cleaning services maintain crystal clear views with professional cleaning techniques and safety equipment.`,
    benefits: [
      {
        title: 'Professional Results',
        description: 'Streak-free cleaning'
      },
      {
        title: 'Safety First',
        description: 'Certified safety procedures'
      },
      {
        title: 'Regular Service',
        description: 'Scheduled maintenance'
      }
    ]
  },
  {
    id: 'tile-stone-cleaning',
    title: 'Specialized Tile & Stone Cleaning',
    shortDescription: 'Expert cleaning and maintenance of tile and stone surfaces.',
    icon: 'FaBrush',
    image: 'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?auto=format&fit=crop&w=800&q=80',
    path: '/services/tile-stone-cleaning',
    features: [
      'Deep Cleaning',
      'Stain Removal',
      'Sealing',
      'Restoration',
      'Maintenance',
      'Surface Protection'
    ],
    detailedDescription: `Our specialized tile and stone cleaning services restore and maintain the beauty of your surfaces using professional techniques and products.`,
    benefits: [
      {
        title: 'Expert Care',
        description: 'Specialized cleaning techniques'
      },
      {
        title: 'Surface Protection',
        description: 'Long-lasting results'
      },
      {
        title: 'Material Knowledge',
        description: 'Surface-specific treatments'
      }
    ]
  },
  {
    id: 'fm-audit',
    title: 'FM Audit',
    shortDescription: 'Comprehensive facility management audits to optimize your building operations.',
    icon: 'FaClipboardCheck',
    image: 'https://images.unsplash.com/photo-1497366216548-37526070297c?auto=format&fit=crop&w=800&q=80',
    path: '/services/fm-audit',
    features: [
      'Facility Assessment',
      'Performance Evaluation',
      'Compliance Review',
      'Cost Analysis',
      'Process Optimization',
      'Risk Assessment'
    ],
    detailedDescription: `Our FM audit services provide a thorough evaluation of your facility management practices, identifying areas for improvement and optimization.`,
    benefits: [
      {
        title: 'Expert Analysis',
        description: 'Detailed assessment by experienced professionals'
      },
      {
        title: 'Actionable Insights',
        description: 'Practical recommendations for improvement'
      },
      {
        title: 'Cost Efficiency',
        description: 'Identify cost-saving opportunities'
      }
    ]
  },
  {
    id: 'hard-services',
    title: 'Hard Services',
    shortDescription: 'Comprehensive maintenance of building systems and infrastructure.',
    icon: 'FaTools',
    image: 'https://images.unsplash.com/photo-1581094794329-c8112a89af12?auto=format&fit=crop&w=800&q=80',
    path: '/services/hard-services',
    features: [
      'HVAC Systems',
      'Electrical Systems',
      'Plumbing',
      'Fire Safety',
      'Building Automation',
      'Preventive Maintenance'
    ],
    detailedDescription: `Our hard services ensure the optimal functioning of your building's critical systems through regular maintenance and prompt repairs.`,
    benefits: [
      {
        title: 'Skilled Technicians',
        description: 'Highly trained maintenance professionals'
      },
      {
        title: '24/7 Support',
        description: 'Round-the-clock emergency response'
      },
      {
        title: 'Preventive Care',
        description: 'Regular maintenance to prevent breakdowns'
      }
    ]
  },
  {
    id: 'soft-services',
    title: 'Soft Services',
    shortDescription: 'Complete range of facility support services for a clean and comfortable environment.',
    icon: 'FaBroom',
    image: 'https://images.unsplash.com/photo-1587578016785-bea53a782ea8?auto=format&fit=crop&w=800&q=80',
    path: '/services/soft-services',
    features: [
      'Cleaning Services',
      'Security',
      'Landscaping',
      'Waste Management',
      'Pest Control',
      'Reception Services'
    ],
    detailedDescription: `Our soft services maintain the cleanliness, security, and overall environment of your facility to ensure comfort and satisfaction for all occupants.`,
    benefits: [
      {
        title: 'Quality Service',
        description: 'High standards of cleanliness and care'
      },
      {
        title: 'Trained Staff',
        description: 'Professional and courteous service personnel'
      },
      {
        title: 'Flexible Solutions',
        description: 'Customized service packages'
      }
    ]
  },
  {
    id: 'mep-installation',
    title: 'MEP Installation',
    shortDescription: 'Expert installation of mechanical, electrical, and plumbing systems.',
    icon: 'FaCogs',
    image: 'https://images.unsplash.com/photo-1581094794329-c8112a89af12?auto=format&fit=crop&w=800&q=80',
    path: '/services/mep-installation',
    features: [
      'Mechanical Systems',
      'Electrical Infrastructure',
      'Plumbing Networks',
      'HVAC Installation',
      'System Integration',
      'Quality Testing'
    ],
    detailedDescription: `Our MEP installation services ensure proper setup and integration of all building systems for optimal performance and efficiency.`,
    benefits: [
      {
        title: 'Expert Installation',
        description: 'Professional MEP system setup'
      },
      {
        title: 'Quality Assurance',
        description: 'Rigorous testing and verification'
      },
      {
        title: 'Ongoing Support',
        description: 'Maintenance and upgrade services'
      }
    ]
  }
]

// Helper function to get service by ID
export const getServiceById = (id) => {
  return services.find(service => service.id === id)
}

// Helper function to get all service paths
export const getServicePaths = () => {
  return services.map(service => service.path)
} 