import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { FaBroom, FaCheckCircle } from 'react-icons/fa'
import { getServiceById } from '../../data/services'

const SoftServices = () => {
  const service = getServiceById('soft-services')

  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[40vh] flex items-center overflow-hidden">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-r from-secondary/80 via-primary/70 to-primary/60" />
          <img
            src={service.image}
            alt={service.title}
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className="container relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl text-white"
          >
            <h1 className="text-5xl md:text-6xl font-display font-bold mb-6">
              {service.title}
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              {service.shortDescription}
            </p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <Link to="/contact" className="btn btn-primary">
                Request Service
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Features Grid */}
      <section className="py-20">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="section-title mb-4">Our Soft Services</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              {service.detailedDescription}
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {service.features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="card p-8 text-center hover:translate-y-[-8px] transition-all duration-300"
              >
                <div className="inline-block p-4 rounded-full bg-primary-50 text-primary text-3xl mb-6">
                  <FaBroom />
                </div>
                <h3 className="text-xl font-bold mb-4">{feature}</h3>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 bg-gradient-light">
        <div className="container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="section-title mb-4">Benefits of Our Soft Services</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Discover how our soft services can enhance your facility environment
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {service.benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="card p-8"
              >
                <div className="inline-block p-4 rounded-full bg-primary-50 text-primary text-3xl mb-6">
                  <FaCheckCircle />
                </div>
                <h3 className="text-xl font-bold mb-4">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-premium text-white">
        <div className="container text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="max-w-3xl mx-auto"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              Ready to Enhance Your Facility Environment?
            </h2>
            <p className="text-xl mb-8">
              Contact us today to discuss your soft services requirements
            </p>
            <div className="flex flex-wrap gap-4 justify-center">
              <Link to="/contact" className="btn bg-white text-primary hover:bg-white/90">
                Get Started
              </Link>
              <Link to="/services" className="btn border-2 border-white text-white hover:bg-white hover:text-primary">
                View All Services
              </Link>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  )
}

export default SoftServices 