import { motion } from 'framer-motion'
import { FaCheck, FaAward, FaCalendarAlt, FaBuilding } from 'react-icons/fa'
import { certifications } from '../data/certifications'

const Certifications = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[40vh] flex items-center overflow-hidden">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-r from-secondary/90 to-primary/90" />
          <img
            src="https://images.unsplash.com/photo-1436491865332-7a61a109cc05?auto=format&fit=crop&w=1600&q=80"
            alt="Our Certifications"
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className="container relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl text-white"
          >
            <h1 className="text-5xl md:text-6xl font-display font-bold mb-6">
              Our Certifications
            </h1>
            <p className="text-xl md:text-2xl">
              Internationally recognized certifications demonstrating our commitment to excellence
            </p>
          </motion.div>
        </div>
      </section>

      {/* Certifications Grid */}
      <section className="py-20">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {certifications.map((cert, index) => (
              <motion.div
                key={cert.id}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="bg-white rounded-xl shadow-premium overflow-hidden"
              >
                <div className="p-8">
                  <div className="flex items-start justify-between mb-6">
                    <div>
                      <h3 className="text-2xl font-bold mb-2">{cert.name}</h3>
                      <div className="flex items-center text-gray-600 mb-4">
                        <FaBuilding className="mr-2" />
                        <span>{cert.authority}</span>
                      </div>
                      <div className="flex items-center text-gray-600">
                        <FaCalendarAlt className="mr-2" />
                        <span>Valid until: {cert.validUntil}</span>
                      </div>
                    </div>
                    <div className="w-20 h-20 bg-gray-100 rounded-lg flex items-center justify-center">
                      <FaAward className="text-4xl text-primary" />
                    </div>
                  </div>
                  
                  <p className="text-gray-600 mb-6">
                    {cert.description}
                  </p>

                  <div>
                    <h4 className="font-semibold mb-4">Certification Scope:</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                      {cert.scope.map((item, i) => (
                        <div key={i} className="flex items-center space-x-2 text-gray-600">
                          <FaCheck className="text-primary flex-shrink-0" />
                          <span>{item}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Trust Banner */}
      <section className="py-16 bg-gradient-light">
        <div className="container">
          <div className="text-center max-w-3xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6">
                Trust in Our Expertise
              </h2>
              <p className="text-xl text-gray-600">
                Our certifications demonstrate our commitment to maintaining the highest standards
                of quality, safety, and service in facility management and building maintenance services.
                These accreditations validate our expertise in delivering professional and reliable solutions
                for all your facility management needs.
              </p>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Certifications 