import { Link } from 'react-router-dom'
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaDirections } from 'react-icons/fa'

const Footer = () => {
  const currentYear = new Date().getFullYear()
  
  const contactInfo = {
    company: 'Everest Light General Maintenance',
    poBox: 'P.O. Box: 27613',
    address: 'Al Yasat Tower, Floor No: 06, Office No: 07',
    location: 'Al Najda Street, Abu Dhabi, UAE',
    phone: '+971 2 6712526',
    email: 'contact@theelgm.com',
    coordinates: {
      lat: 24.4900325,
      lng: 54.3705355
    }
  }

  const quickLinks = [
    { name: 'About Us', path: '/about' },
    { name: 'Services', path: '/services' },
    { name: 'Projects', path: '/projects' },
    { name: 'Certifications', path: '/certifications' },
    { name: 'Careers', path: '/careers' },
    { name: 'Contact', path: '/contact' }
  ]

  const socialLinks = [
    { icon: <FaFacebookF />, url: '#', label: 'Facebook' },
    { icon: <FaTwitter />, url: '#', label: 'Twitter' },
    { icon: <FaLinkedinIn />, url: '#', label: 'LinkedIn' },
    { icon: <FaInstagram />, url: '#', label: 'Instagram' }
  ]

  const handleGetDirections = () => {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${contactInfo.coordinates.lat},${contactInfo.coordinates.lng}`, '_blank')
  }

  return (
    <footer className="bg-gray-900 text-white pt-16 pb-8">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {/* Company Info */}
          <div>
            <img src="/elgm.png" alt="ELGM" className="h-22 mb-6" />
            <p className="text-gray-400 mb-6">
              Excellence in facility management services across the UAE, delivering quality and reliability since 2014.
            </p>
            <div className="flex space-x-4">
              {socialLinks.map((social) => (
                <a
                  key={social.label}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center hover:bg-primary transition-colors duration-300"
                  aria-label={social.label}
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-xl font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              {quickLinks.map((link) => (
                <li key={link.name}>
                  <Link
                    to={link.path}
                    className="text-gray-400 hover:text-white transition-colors duration-300"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-xl font-bold mb-4">Contact Info</h3>
            <ul className="space-y-4">
              <li className="flex items-start space-x-3">
                <FaPhone className="text-primary mt-1" />
                <span className="text-gray-400">
                  <a href={`tel:${contactInfo.phone}`} className="hover:text-white transition-colors duration-300">
                    {contactInfo.phone}
                  </a>
                </span>
              </li>
              <li className="flex items-start space-x-3">
                <FaEnvelope className="text-primary mt-1" />
                <span className="text-gray-400">
                  <a href={`mailto:${contactInfo.email}`} className="hover:text-white transition-colors duration-300">
                    {contactInfo.email}
                  </a>
                </span>
              </li>
              <li className="flex items-start space-x-3">
                <FaMapMarkerAlt className="text-primary mt-1" />
                <div className="text-gray-400">
                  <p>{contactInfo.company}</p>
                  <p>{contactInfo.poBox}</p>
                  <p>{contactInfo.address}</p>
                  <p>{contactInfo.location}</p>
                  <button
                    onClick={handleGetDirections}
                    className="flex items-center text-primary hover:text-primary-600 mt-2 transition-colors duration-300"
                  >
                    <FaDirections className="mr-2" />
                    Get Directions
                  </button>
                </div>
              </li>
            </ul>
          </div>

          {/* Working Hours */}
          <div>
            <h3 className="text-xl font-bold mb-4">Working Hours</h3>
            <ul className="space-y-2 text-gray-400">
              <li>Monday - Friday</li>
              <li className="font-semibold text-white">8:00 AM - 6:00 PM</li>
              <li className="mt-4">Saturday</li>
              <li className="font-semibold text-white">9:00 AM - 2:00 PM</li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>© {currentYear} {contactInfo.company}. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer 