import { motion } from 'framer-motion'
import { FaHandshake, FaLightbulb, FaUsers, FaChartLine, FaBuilding, FaTools, FaUserTie } from 'react-icons/fa'

const About = () => {
  const values = [
    {
      icon: <FaHandshake className="text-4xl text-primary" />,
      title: 'Integrity',
      description: 'We conduct our business with the highest standards of professionalism and ethics.'
    },
    {
      icon: <FaLightbulb className="text-4xl text-primary" />,
      title: 'Innovation',
      description: 'We continuously seek innovative solutions to improve our services.'
    },
    {
      icon: <FaUsers className="text-4xl text-primary" />,
      title: 'Customer Focus',
      description: "Our clients' success is our priority, and we strive to exceed their expectations."
    },
    {
      icon: <FaChartLine className="text-4xl text-primary" />,
      title: 'Excellence',
      description: 'We are committed to delivering excellence in everything we do.'
    }
  ]

  const expertise = [
    {
      icon: <FaBuilding className="text-3xl text-primary" />,
      title: 'Facility Management',
      description: 'Comprehensive facility management solutions for all types of buildings'
    },
    {
      icon: <FaTools className="text-3xl text-primary" />,
      title: 'Maintenance Services',
      description: 'Professional maintenance services for optimal building performance'
    },
    {
      icon: <FaUserTie className="text-3xl text-primary" />,
      title: 'Professional Team',
      description: 'Experienced professionals dedicated to excellence in service delivery'
    }
  ]

  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[40vh] flex items-center overflow-hidden">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-r from-secondary/90 to-primary/90" />
          <img
            src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&w=1600&q=80"
            alt="About Us"
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className="container relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl text-white"
          >
            <h1 className="text-5xl md:text-6xl font-display font-bold mb-6">
              About Us
            </h1>
            <p className="text-xl md:text-2xl">
              Leading provider of comprehensive facility management solutions
            </p>
          </motion.div>
        </div>
      </section>

      {/* Company Story */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl font-bold mb-6">Our Story</h2>
              <p className="text-gray-600 mb-4">
                ELGM has been at the forefront of facility management in the UAE, providing comprehensive
                maintenance solutions to businesses and organizations of all sizes.
              </p>
              <p className="text-gray-600 mb-4">
                With years of experience and a team of skilled professionals, we have built a reputation
                for excellence in service delivery and customer satisfaction.
              </p>
              <p className="text-gray-600">
                Our commitment to quality, innovation, and customer service has made us a trusted partner
                for facilities maintenance across the region.
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="relative"
            >
              <img
                src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&w=800&q=80"
                alt="Modern Building"
                className="rounded-lg shadow-xl"
              />
            </motion.div>
          </div>
        </div>
      </section>

      {/* Our Expertise */}
      <section className="py-20 bg-gradient-light">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-bold mb-6">Our Expertise</h2>
            <p className="text-gray-600 text-xl max-w-2xl mx-auto">
              We specialize in providing comprehensive facility management and maintenance services
              that ensure optimal building performance and occupant comfort.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {expertise.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="card p-8 text-center"
              >
                <div className="inline-block p-4 rounded-full bg-primary-50 mb-6">
                  {item.icon}
                </div>
                <h3 className="text-xl font-bold mb-4">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Our Values */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-bold mb-6">Our Values</h2>
            <p className="text-gray-600 text-xl max-w-2xl mx-auto">
              Our core values guide everything we do and shape our commitment to excellence
              in facility management.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="card p-8 text-center"
              >
                <div className="inline-block p-4 rounded-full bg-primary-50 mb-6">
                  {value.icon}
                </div>
                <h3 className="text-xl font-bold mb-4">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-20 bg-primary text-white">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            className="max-w-3xl mx-auto"
          >
            <h2 className="text-3xl lg:text-4xl font-bold mb-6">Our Mission</h2>
            <p className="text-xl mb-8">
              To provide exceptional facility management services that enhance the value of our clients'
              properties while ensuring safety, comfort, and efficiency through innovative solutions and
              dedicated service.
            </p>
          </motion.div>
        </div>
      </section>
    </div>
  )
}

export default About 