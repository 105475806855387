export const certifications = [
  {
    id: 'easa-145',
    name: 'EASA Part 145',
    authority: 'European Union Aviation Safety Agency',
    logo: '/certifications/easa.svg', // You'll need to add these logos
    description: 'Approved Maintenance Organization certification for aircraft maintenance.',
    validUntil: '2025',
    scope: [
      'Line Maintenance',
      'Base Maintenance',
      'Component Maintenance',
      'Specialized Services'
    ]
  },
  {
    id: 'gcaa',
    name: 'GCAA CAR 145',
    authority: 'UAE General Civil Aviation Authority',
    logo: '/certifications/gcaa.svg',
    description: 'UAE certification for aircraft maintenance and repair operations.',
    validUntil: '2025',
    scope: [
      'Aircraft Maintenance',
      'Component Overhaul',
      'NDT Services',
      'Aviation Training'
    ]
  },
  {
    id: 'iso-9001',
    name: 'ISO 9001:2015',
    authority: 'International Organization for Standardization',
    logo: '/certifications/iso.svg',
    description: 'Quality Management System certification ensuring highest standards of service delivery.',
    validUntil: '2024',
    scope: [
      'Quality Management',
      'Process Control',
      'Customer Satisfaction',
      'Continuous Improvement'
    ]
  },
  {
    id: 'as9110',
    name: 'AS9110 Rev.C',
    authority: 'International Aerospace Quality Group',
    logo: '/certifications/as9110.svg',
    description: 'Aerospace quality management system certification for maintenance organizations.',
    validUntil: '2024',
    scope: [
      'Aerospace Operations',
      'Quality Control',
      'Safety Management',
      'Risk Assessment'
    ]
  }
] 