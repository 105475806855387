import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import Layout from './layouts/Layout'
import Home from './pages/Home'
import About from './pages/About'
import Services from './pages/Services'
import Projects from './pages/Projects'
import Certifications from './pages/Certifications'
import Careers from './pages/Careers'
import Contact from './pages/Contact'
import FMAudit from './pages/services/FMAudit'
import HardServices from './pages/services/HardServices'
import SoftServices from './pages/services/SoftServices'
import MEPInstallation from './pages/services/MEPInstallation'
import './index.css'

function App() {
  return (
    <Router>
      <AnimatePresence mode="wait">
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/certifications" element={<Certifications />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services/fm-audit" element={<FMAudit />} />
            <Route path="/services/hard-services" element={<HardServices />} />
            <Route path="/services/soft-services" element={<SoftServices />} />
            <Route path="/services/mep-installation" element={<MEPInstallation />} />
          </Routes>
        </Layout>
      </AnimatePresence>
    </Router>
  )
}

export default App
