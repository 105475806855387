/**
 * Projects Data
 * 
 * To add a new project:
 * 1. Copy an existing project object
 * 2. Update the id (must be unique)
 * 3. Fill in the project details
 * 4. Add it to the projects array
 * 
 * Image Guidelines:
 * - Recommended size: 1600x900px
 * - Format: JPG or PNG
 * - Keep file size under 500KB
 */

export const projects = [
  {
    id: 'dubai-mall-fm',              // Unique identifier (used in URLs)
    title: 'Dubai Mall Facility Management',
    location: 'Dubai, UAE',
    image: 'https://images.unsplash.com/photo-1584907797015-7554cd315667?auto=format&fit=crop&w=1600&q=80',
    shortDescription: 'Comprehensive facility management for the world\'s largest shopping mall.',
    
    // Detailed information shown in modal
    overview: 'Complete facility management solution for Dubai Mall, encompassing over 12 million square feet of retail space, serving 80 million visitors annually.',
    challenge: 'Managing a complex, high-traffic facility while maintaining world-class standards and ensuring uninterrupted operations 24/7.',
    solution: 'Implemented an integrated facility management system including advanced BMS, predictive maintenance, energy optimization, 24/7 emergency response, and specialized retail maintenance.',
    results: 'Achieved 30% reduction in energy consumption, 99.9% uptime for critical systems, and maintained exceptional customer satisfaction ratings.',
    
    // Optional metadata
    year: '2023',
    client: 'Emaar Properties',
    sector: 'Retail',
    services: [
      'Facility Management',
      'MEP Maintenance',
      'Energy Management',
      'Security Systems'
    ]
  },
  {
    id: 'burj-khalifa-maintenance',
    title: 'Burj Khalifa MEP Maintenance',
    location: 'Dubai, UAE',
    image: 'https://images.unsplash.com/photo-1582573732277-c5444fa37391?auto=format&fit=crop&w=1600&q=80',
    shortDescription: 'Strategic MEP maintenance program for the world\'s tallest building.',
    overview: 'Comprehensive MEP maintenance strategy for the 828-meter tall Burj Khalifa, managing all mechanical, electrical, and plumbing systems.',
    challenge: 'Maintaining complex systems at extreme heights while ensuring continuous operation of residential, commercial, and hospitality spaces.',
    solution: 'Developed a multi-tier maintenance approach including vertical transportation, HVAC optimization, water distribution, power management, and smart building automation.',
    results: 'Maintained 99.99% systems reliability, reduced energy consumption by 25%, and achieved zero major system failures.',
    year: '2023',
    client: 'Emaar Properties',
    sector: 'Mixed-Use',
    services: [
      'MEP Maintenance',
      'Vertical Transportation',
      'Energy Management',
      'Building Automation'
    ]
  },
  {
    id: 'dubai-healthcare-city',
    title: 'Dubai Healthcare City Facilities',
    location: 'Dubai, UAE',
    image: 'https://images.unsplash.com/photo-1596541223130-5d31a73fb6c6?auto=format&fit=crop&w=1600&q=80',
    shortDescription: 'Specialized healthcare facility management across multiple buildings.',
    overview: 'End-to-end facility management for Dubai Healthcare City\'s medical facilities, laboratories, and healthcare infrastructure.',
    challenge: 'Maintaining stringent healthcare standards while managing diverse medical facilities with specific requirements.',
    solution: 'Implemented specialized healthcare facility solutions including medical waste management, clean room maintenance, HVAC sanitization, and infection control protocols.',
    results: 'Achieved 100% compliance with healthcare regulations, reduced operational costs by 20%, and maintained sterile environments.',
    year: '2023',
    client: 'Dubai Healthcare City Authority',
    sector: 'Healthcare',
    services: [
      'Healthcare Facility Management',
      'Medical Waste Management',
      'Clean Room Maintenance',
      'HVAC Sanitization'
    ]
  },
  {
    id: 'dubai-metro-stations',
    title: 'Dubai Metro Stations Maintenance',
    location: 'Dubai, UAE',
    image: 'https://images.unsplash.com/photo-1565012686938-92d8fc285d14?auto=format&fit=crop&w=1600&q=80',
    shortDescription: 'Integrated maintenance program for Dubai Metro stations.',
    overview: 'Comprehensive maintenance management for multiple Dubai Metro stations, ensuring optimal functionality and passenger comfort.',
    challenge: 'Maintaining high-traffic public transportation facilities while ensuring minimal disruption to daily operations.',
    solution: 'Deployed maintenance strategy including platform screen doors, HVAC, electrical systems, public area sanitization, and emergency response systems.',
    results: 'Maintained 99.9% station uptime, improved energy efficiency by 35%, and enhanced passenger satisfaction scores.',
    year: '2023',
    client: 'Roads and Transport Authority',
    sector: 'Transportation',
    services: [
      'Station Maintenance',
      'MEP Systems',
      'Public Area Management',
      'Emergency Systems'
    ]
  },
  {
    id: 'mall-emirates-renovation',
    title: 'Mall of the Emirates Renovation',
    location: 'Dubai, UAE',
    image: 'https://images.unsplash.com/photo-1519567770579-c2fc5a52f11c?auto=format&fit=crop&w=1600&q=80',
    shortDescription: 'Major renovation and facilities upgrade project.',
    overview: 'Complete renovation and facilities upgrade for Mall of the Emirates, including modernization of all major systems.',
    challenge: 'Executing major renovations while maintaining normal mall operations and minimizing impact on retailers and visitors.',
    solution: 'Implemented phased renovation including HVAC modernization, lighting upgrades, common area refurbishment, and smart building integration.',
    results: 'Completed renovation ahead of schedule, reduced energy consumption by 40%, and increased visitor satisfaction ratings.',
    year: '2023',
    client: 'Majid Al Futtaim',
    sector: 'Retail',
    services: [
      'Renovation Management',
      'MEP Upgrades',
      'Smart Building Integration',
      'Energy Optimization'
    ]
  }
]

/**
 * Helper Functions
 */

// Get a single project by its ID
export const getProjectById = (id) => {
  return projects.find(project => project.id === id)
}

// Get projects by sector
export const getProjectsBySector = (sector) => {
  if (!sector) return projects
  return projects.filter(project => project.sector === sector)
}

// Get projects by year
export const getProjectsByYear = (year) => {
  if (!year) return projects
  return projects.filter(project => project.year === year)
}

// Get all unique sectors
export const getAllSectors = () => {
  return [...new Set(projects.map(project => project.sector))]
}

// Get all unique services
export const getAllServices = () => {
  const services = projects.flatMap(project => project.services)
  return [...new Set(services)]
} 