import { motion } from 'framer-motion'
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock, FaDirections } from 'react-icons/fa'

const Contact = () => {
  const contactInfo = [
    {
      icon: <FaPhone className="text-2xl text-primary" />,
      title: 'Phone',
      details: '+971 2 6712526'
    },
    {
      icon: <FaEnvelope className="text-2xl text-primary" />,
      title: 'Email',
      details: 'contact@theelgm.com'
    },
    {
      icon: <FaMapMarkerAlt className="text-2xl text-primary" />,
      title: 'Location',
      details: 'Al Najda Street, Abu Dhabi, UAE'
    },
    {
      icon: <FaClock className="text-2xl text-primary" />,
      title: 'Business Hours',
      details: 'Mon - Fri: 8:00 AM - 6:00 PM\nSat: 9:00 AM - 2:00 PM'
    }
  ]

  const companyInfo = {
    company: 'Everest Light General Maintenance Co',
    poBox: 'P.O. Box: 27613',
    address: 'Al Yasat Tower, Floor No: 06, Office No: 07',
    location: 'Al Najda Street, Abu Dhabi, UAE',
    coordinates: {
      lat: 24.4900325,
      lng: 54.3705355
    }
  }

  const handleGetDirections = () => {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${companyInfo.coordinates.lat},${companyInfo.coordinates.lng}`, '_blank')
  }

  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[40vh] flex items-center overflow-hidden">
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-gradient-to-r from-secondary/80 via-primary/70 to-primary/60" />
          <img
            src="https://images.unsplash.com/photo-1497366216548-37526070297c?auto=format&fit=crop&w=1600&q=80"
            alt="Contact Us"
            className="w-full h-full object-cover"
          />
        </div>
        
        <div className="container relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-3xl text-white"
          >
            <h1 className="text-5xl md:text-6xl font-display font-bold mb-6">
              Contact Us
            </h1>
            <p className="text-xl md:text-2xl">
              Let's discuss how our services can enhance your building's performance and efficiency
            </p>
          </motion.div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="py-16">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Contact Information */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h2 className="text-3xl font-bold mb-8">Get in Touch</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                {contactInfo.map((info, index) => (
                  <div key={index} className="flex items-start space-x-4">
                    <div className="mt-1">{info.icon}</div>
                    <div>
                      <h3 className="font-semibold mb-1">{info.title}</h3>
                      <p className="text-gray-600 whitespace-pre-line">{info.details}</p>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>

            {/* Contact Form */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="bg-white rounded-lg p-6 shadow-md"
            >
              <form className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-gray-700 mb-2">Name</label>
                    <input
                      type="text"
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                      placeholder="Your Name"
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700 mb-2">Email</label>
                    <input
                      type="email"
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                      placeholder="Your Email"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-gray-700 mb-2">Subject</label>
                  <input
                    type="text"
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
                    placeholder="Subject"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 mb-2">Message</label>
                  <textarea
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent h-32"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="w-full bg-primary text-white py-3 rounded-lg hover:bg-primary-600 transition-colors duration-300"
                >
                  Send Message
                </button>
              </form>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Map Section */}
      <section className="pb-16">
        <div className="container">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold mb-2">Visit Our Office</h2>
            <p className="text-gray-600">
              Come visit us at our office to discuss your facility management needs.
            </p>
          </div>
          
          <div className="relative h-[500px] rounded-lg overflow-hidden">
            {/* Google Map */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3631.8649773524695!2d54.36834287538385!3d24.490032477152063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjTCsDI5JzI0LjEiTiA1NMKwMjInMTMuNCJF!5e0!3m2!1sen!2sae!4v1710835896040!5m2!1sen!2sae"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            {/* Location Card Overlay */}
            <div className="absolute top-4 left-4 bg-white p-6 rounded-lg shadow-lg max-w-sm">
              <h3 className="text-xl font-bold text-primary mb-2">{companyInfo.company}</h3>
              <p className="text-gray-600 mb-4">{companyInfo.poBox}</p>
              <div className="flex items-center text-gray-600 mb-4">
                <FaMapMarkerAlt className="mr-2" />
                <div>
                  <p>{companyInfo.address}</p>
                  <p>{companyInfo.location}</p>
                </div>
              </div>
              <button
                onClick={handleGetDirections}
                className="btn btn-primary w-full flex items-center justify-center"
              >
                <FaDirections className="mr-2" />
                Get Directions
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Contact 